.progress-bar {
    animation: loadProgress 2s forwards;
  }

  @keyframes loadProgress {
    0% {
      width: 0%;
    }
    50% {
      width: 50%;
    }
    100% {
        width: 100%;
    }
  }