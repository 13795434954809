body {
  font-family: "Open Sans", sans-serif;
  
}

.active {
  background-color: #0000ff;
  color: #fff;
  border: none;
  padding-left: 10px;
  transition: all 0.3s;
}
/* In a separate CSS file or within a <style> tag in your HTML */
/* In a separate CSS file or within a <style> tag in your HTML */
/* In a separate CSS file or within a <style> tag in your HTML */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scrollVertical {
   0%, 15% { transform: translateY(0); }
      25%, 40% { transform: translateY(-50%); }
     
      75%, 90% { transform: translateY(-25%); }
      100% { transform: translateY(0); }
}
.scrolling {
  animation: scrollVertical 8s infinite;
}
.fade-in {
  animation: fadeIn 1.2s ease-out forwards;
}

.hover-lively {
  transition: transform 3.5s ease-in-out;
}

.hover-lively:hover {
  transform: scale(1.1) rotate(5deg) translateY(-10px);
}


